import React, { useReducer, useEffect } from "react";
import Cookies from "js-cookie";
import axios2 from "axios";
import _ from "lodash";
import { BrowserRouter , Route , Routes } from "react-router-dom";
import { ConfigProvider, App as AppAntd } from "antd";
import dayjs, {Dayjs} from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import language from "./resources/languages/en_US";
import { AntDesign, ActionType, API } from "./resources/constants";

// Commons
import PendingRequest from './commons/pending-request'
import AuthRoute from './commons/auth-route'
import NoAuthRoute from './commons/no-auth-route'
import ScrollToTop from './commons/scroll-to-top'
import { getSources, getTenders } from './commons/tender'

// Interfaces
import { AxiosError } from "./interfaces/axios";
import { InternalAxiosRequestConfig, AxiosResponse } from "axios";

// Reducers
import GlobalReducer from './reducers/global'
import AxiosReducer from './reducers/axios'

// Contexts
import GlobalContext from './contexts/global'
import AxiosContext from './contexts/axios'

// Pages
import Dashboard from "./pages/dashboard/dashboard"

import Opportunities from "./pages/opportunities/opportunities"
import Tender from "./pages/opportunities/tender/tender"
import AskHistory from "./pages/opportunities/ask-history"

import SavedOpportunities from "./pages/saved-opportunities/saved-opportunities"

import Payment from "./pages/payment/payment"
import PaymentSuccess from "./pages/payment/payment-success"
import PaymentError from "./pages/payment/payment-error"

import Profile from "./pages/profile/profile"
import MyAccount from "./pages/profile/my-account"
import Overview from "./pages/profile/overview"
import Classifications from "./pages/profile/classifications"
import AIFiles from "./pages/profile/ai-files"
import Members from "./pages/profile/members"
import PaymentHistory from "./pages/profile/payment-history"
import DeleteAccount from "./pages/profile/delete-account"

import SignIn from "./pages/sign-in/sign-in"
import SignUp from "./pages/sign-up/sign-up"
import ResetPassword from "./pages/reset-password/reset-password"
import InviteMember from "./pages/invite-member/invite-member"
import NotFound from "./pages/not-found"
import Forbidden from "./pages/forbidden"

// Layouts
import DashboardLayout from "./layouts/dashboard"
import PublicLayout from "./layouts/public"

dayjs.extend(utc);
dayjs.extend(timezone);
const { SET_USER, SET_ACCESS_TOKEN } = ActionType
const { USER_MANAGEMENT_PROFILE_MY_ACCOUNT } = API;

export default function App() {

    const [{
        user,
        sources,
        tendersCount,
        tenders,
        tendersPagination,
        filter,
        primaryFilter,
        accessToken,
    }, dispatchGlobal] = useReducer(GlobalReducer , {
        user: null,
        sources: [],
        tendersCount: 0,
        tenders: [],
        tendersPagination: {
            page: 1,
            pageSize: 6,
            total: 0,
        },
        filter: {},
        primaryFilter: {
            expired: true,
            eligible: true,
        },
        accessToken: Cookies.get("accessToken"),
    })

    const [{
        pendingRequests,
        axios,
    }, dispatchAxios] = useReducer(AxiosReducer , {
        pendingRequests: 0,
        axios: axios2.create({
            baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL,
            timeout: Number(process.env.REACT_APP_TIME_OUT),
            withCredentials: true,
        })
    })

    const pendingRequest = (counter: number, loading: boolean = true) => {
        const element = document.getElementById('pending-request')
        if (element) element.style.display = (counter && loading) ? "flex" : "none";
    }

    useEffect(() => {
        let counter = 0;

        // All api request
        axios.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                //dispatchAxios({ type: SET_PENDING_REQUESTS , payload: { plus: true }})
                pendingRequest(++counter, config?.params?.loading)
                const accessToken = Cookies.get("accessToken")
                config.headers.Authorization = accessToken && `Bearer ${accessToken}`
                return config
            },
            (error: any) => Promise.reject(error)
        );

        // All api response
        axios.interceptors.response.use(
            (response: AxiosResponse ) => {
                //dispatchAxios({ type: SET_PENDING_REQUESTS , payload: { plus: false }})
                pendingRequest(--counter, response?.config?.params?.loading)
                return response
            },
            (error: AxiosError|any) => {
                const { response: { status } } = error
                if (status === 401) {
                    Cookies.remove('accessToken')
                    dispatchGlobal({ type: SET_ACCESS_TOKEN , payload: { accessToken: null }})
                    dispatchGlobal({ type: SET_USER , payload: { user: null }})
                }

                //dispatchAxios({ type: SET_PENDING_REQUESTS , payload: { plus: false }})
                pendingRequest(--counter, error?.config?.params?.loading)
                return Promise.reject(error)
            }
        );

        axios.get(USER_MANAGEMENT_PROFILE_MY_ACCOUNT).then((response: { data: {} }) => {
            const { data: user } = response
            dispatchGlobal({ type: SET_USER , payload: { user }})
        }).catch( (error: {}) => error );

    }, [])

    useEffect(() => {
        user && getSources(axios, dispatchGlobal);
        user && getTenders(axios, primaryFilter, dispatchGlobal);
    }, [user])

    return (
            <ConfigProvider
                direction={"ltr"}
                locale={language}
                theme={AntDesign.theme}
            >
                <AppAntd>
                    <PendingRequest pendingRequests={pendingRequests}/>
                    <GlobalContext.Provider value={{
                        user,
                        sources,
                        tendersCount,
                        tenders,
                        tendersPagination,
                        filter,
                        primaryFilter,
                        accessToken,
                        dispatch: dispatchGlobal
                    }}>
                        <AxiosContext.Provider value={{
                            pendingRequests,
                            axios,
                            dispatch: dispatchAxios
                        }}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/" element={<AuthRoute><DashboardLayout /></AuthRoute>} >
                                        <Route index element={<AuthRoute roles={["ADMIN", "MEMBER"]}><Dashboard /></AuthRoute>} />
                                        <Route path="opportunities" >
                                            <Route index element={<AuthRoute roles={["ADMIN", "MEMBER"]}><Opportunities /></AuthRoute>} />
                                            <Route path="tender/:id" element={<AuthRoute roles={["ADMIN", "MEMBER"]}><Tender /></AuthRoute>} />
                                            <Route path="ask-history/:source" element={<AuthRoute roles={["ADMIN", "MEMBER"]}><AskHistory /></AuthRoute>} />
                                        </Route>
                                        <Route path="saved-opportunities" element={<AuthRoute roles={["ADMIN", "MEMBER"]}><SavedOpportunities /></AuthRoute>} />
                                        <Route path="payment" >
                                            <Route index element={<Payment />} />
                                            <Route path="success" element={<PaymentSuccess />} />
                                            <Route path="error" element={<PaymentError />} />
                                        </Route>
                                        <Route path="profile" element={<Profile />} >
                                            <Route path="my-account" element={<MyAccount />} />
                                            <Route path="overview" element={<AuthRoute roles={["ADMIN"]}><Overview /></AuthRoute>} />
                                            <Route path="classifications" element={<AuthRoute roles={["ADMIN"]}><Classifications /></AuthRoute>} />
                                            <Route path="ai-files" element={<AuthRoute roles={["ADMIN"]}><AIFiles /></AuthRoute>} />
                                            <Route path="members" element={<AuthRoute roles={["ADMIN"]}><Members /></AuthRoute>} />
                                            <Route path="payment-history" element={<AuthRoute roles={["ADMIN"]}><PaymentHistory /></AuthRoute>} />
                                            <Route path="delete-account" element={<AuthRoute roles={["ADMIN"]}><DeleteAccount /></AuthRoute>} />
                                            <Route index path="*" element={<NotFound />} />
                                        </Route>
                                        <Route path="invite-member/:admin" element={<InviteMember />} />
                                        <Route path="forbidden" element={<Forbidden />} />
                                    </Route>
                                    <Route path="/" element={<NoAuthRoute><PublicLayout /></NoAuthRoute>} >
                                        <Route path="sign-up" element={<SignUp />} />
                                        <Route path="sign-in" element={<SignIn />} />
                                        <Route path="reset-password" element={<ResetPassword />} />
                                        <Route path="reset-password/:token" element={<ResetPassword />} />
                                    </Route>
                                    <Route element={<PublicLayout />} >
                                        <Route path="*" element={<NotFound />} />
                                    </Route>
                                </Routes>
                                <ScrollToTop />
                            </BrowserRouter>
                        </AxiosContext.Provider>
                    </GlobalContext.Provider>
                </AppAntd>
            </ConfigProvider>
    )
}
