// API Url Constants

export default {
    USER_MANAGEMENT_AUTH_SIGN_IN: "v1/user-management/auth/sign-in",
    USER_MANAGEMENT_AUTH_OTP_CODE: "v1/user-management/auth/otp-code/",
    USER_MANAGEMENT_AUTH_SIGN_UP: "v1/user-management/auth/sign-up",
    USER_MANAGEMENT_AUTH_SIGN_IN_GOOGLE: "v1/user-management/auth/sign-in-google",
    USER_MANAGEMENT_AUTH_REQUEST_RESET_PASSWORD: "v1/user-management/auth/request-reset-password",
    USER_MANAGEMENT_AUTH_RESET_PASSWORD: "v1/user-management/auth/reset-password",
    USER_MANAGEMENT_AUTH_CAPTCHA: "v1/user-management/auth/captcha",

    USER_MANAGEMENT_PROFILE_MY_ACCOUNT: "v1/user-management/profile",
    USER_MANAGEMENT_PROFILE_UPDATE: "v1/user-management/profile",
    USER_MANAGEMENT_PROFILE_UPDATE_AVATAR: "v1/user-management/profile/avatar",
    USER_MANAGEMENT_PROFILE_GET_OVERVIEW: "v1/user-management/profile/overview",
    USER_MANAGEMENT_PROFILE_SET_OVERVIEW: "v1/user-management/profile/overview",
    USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION: "v1/user-management/profile/classification",
    USER_MANAGEMENT_PROFILE_SET_CLASSIFICATION: "v1/user-management/profile/classification",
    USER_MANAGEMENT_PROFILE_MEMBER_INVITE: "v1/user-management/profile/member/invite/",
    USER_MANAGEMENT_PROFILE_MEMBER_ADD: "v1/user-management/profile/member/add/",
    USER_MANAGEMENT_PROFILE_MEMBER_REMOVE: "v1/user-management/profile/member/",
    USER_MANAGEMENT_PROFILE_MEMBER_ALL: "v1/user-management/profile/member/all",
    USER_MANAGEMENT_PROFILE_AI_FILE_ALL: "v1/user-management/profile/ai-file/all",
    USER_MANAGEMENT_PROFILE_GET_AI_FILE: "v1/user-management/profile/ai-file/",
    USER_MANAGEMENT_PROFILE_AI_FILE_REMOVE: "v1/user-management/profile/ai-file/",
    USER_MANAGEMENT_PROFILE_AI_FILE_ADD: "v1/user-management/profile/ai-file",
    USER_MANAGEMENT_PROFILE_GET_AI_QUESTION: "v1/user-management/profile/ai-question",
    USER_MANAGEMENT_PROFILE_SET_AI_QUESTION: "v1/user-management/profile/ai-question",
    USER_MANAGEMENT_PROFILE_INVOICE_ALL: "v1/user-management/profile/invoice/all",
    USER_MANAGEMENT_PROFILE_INVOICE_VERIFY: "v1/user-management/profile/invoice/verify/",
    USER_MANAGEMENT_PROFILE_ACCOUNT_DELETE: "v1/user-management/profile/delete-account",
    USER_MANAGEMENT_PROFILE_STRIPE: "v1/user-management/profile/stripe/",

    TENDER_TENDER_PAGINATE: "v1/tender/tender/paginate",
    TENDER_TENDER_ID: "v1/tender/tender/",
    TENDER_TENDER_LIKE: "v1/tender/tender/like/",
    TENDER_TENDER_DISLIKE: "v1/tender/tender/dislike/",
    TENDER_TENDER_BOOKMARK: "v1/tender/tender/bookmark/",
    TENDER_TENDER_BOOKMARK_ALL: "v1/tender/tender/bookmark/all",
    TENDER_TENDER_DOWNLOAD_FILE: "v1/tender/tender/download/",
    TENDER_SET_ASIDE_CODE_PAGINATE: "v1/tender/set-aside-code/paginate",
    TENDER_NOTICE_TYPE_PAGINATE: "v1/tender/notice-type/paginate",
    TENDER_SOURCE_PAGINATE: "v1/tender/source/paginate",
    TENDER_BUYER_PAGINATE: "v1/tender/buyer/paginate",
    TENDER_NAICS_CODE_PAGINATE: "v1/tender/naics-code/paginate",
    TENDER_PSC_CODE_PAGINATE: "v1/tender/psc-code/paginate",

    BASIC_INFORMATION_COUNTRY_ALL: "v1/basic-information/country/all",

    DOWNLOAD: "v1/download/",
}
