import React, {useContext, useState, useEffect, MouseEvent} from 'react'
import _ from 'lodash'
import { useNavigate, useLocation, Link } from "react-router-dom"
import { Helmet } from 'react-helmet'
import { Row, Col, Input, Button, Space, Pagination, Card, Flex, Typography, Tag, Form, DatePicker, Checkbox, Select, AutoComplete, Empty, App, Tooltip, Affix } from 'antd'
import {
    FaTimes,
    FaArrowCircleRight,
    FaSearch,
    FaRegThumbsUp,
    FaThumbsUp,
    FaRegThumbsDown,
    FaThumbsDown,
    FaRegBookmark,
    FaBookmark,
    FaLink,
    FaFilter,
    FaFileAlt,
    FaFolderOpen,
    FaChartPie,
} from "react-icons/fa";
import dayjs, { Dayjs } from 'dayjs'
import { API, Enum, Regex, ActionType } from "../../resources/constants"
import language from "../../resources/languages/en_US"

// Interfaces
import {Tender, Filter, PrimaryFilter} from "../../interfaces/pages/opportunities"
import { ClassificationsForm } from "../../interfaces/pages/profile"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import GlobalContext from "../../contexts/global";
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Opportunities }, Share, Validation, Message: MessageLanguage, Enum: EnumLanguage } = language
const { TENDER_TENDER_PAGINATE, TENDER_NAICS_CODE_PAGINATE, TENDER_BUYER_PAGINATE, TENDER_TENDER_LIKE, TENDER_TENDER_DISLIKE, TENDER_TENDER_BOOKMARK, TENDER_PSC_CODE_PAGINATE, USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, TENDER_SET_ASIDE_CODE_PAGINATE, TENDER_NOTICE_TYPE_PAGINATE } = API;
const { SET_FILTER, SET_TENDERS_PAGINATION, SET_TENDERS, SET_PRIMARY_FILTER } = ActionType;
const { Message } = Enum
const { isNumber } = Regex
const siteURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_SITE_URL : process.env.REACT_APP_PRODUCTION_SITE_URL
const filters: { key: string, filter: Filter }[] = []
const PVB = ['Max', 'Min']
const PTS = ['Max', 'Min']

export default function Index() {

    const { sources, user, filter, primaryFilter, tenders, tendersPagination, dispatch } = useContext(GlobalContext)
    const { axios } = useContext(AxiosContext)
    const [search, setSearch] = useState<string>('');
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [naicsCodes, setNAICSCodes] = useState<string[]>([]);
    const [pscCodes, setPSCCodes] = useState<string[]>([]);
    const [setAsideCodes, setSetAsideCodes] = useState<{ id: string, name: string }[]>([]);
    const [noticeTypes, setNoticeTypes] = useState<{ id: string, name: string }[]>([]);
    const [naicsCodeOptions, setNAICSCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [buyerIncludeOptions, setBuyerIncludeOptions] = useState<{ name: string }[]>([]);
    const [buyersInclude, setBuyersInclude] = useState<string[]>([]);
    const [buyerExcludeOptions, setBuyerExcludeOptions] = useState<{ name: string }[]>([]);
    const [buyersExclude, setBuyersExclude] = useState<string[]>([]);
    const [pscCodeOptions, setPSCCodeOptions] = useState<{ label: string, value: string }[]>([]);
    const [form] = Form.useForm()
    const [, forceUpdate] = useState({})
    const [loadingTender, setLoadingTender] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()
    const { message } = App.useApp()

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({})
        initialAPI().then(response => response)
    }, []);

    useEffect(() => {
        if (location) {
            const currentFilter = _.findLast(filters, { key: location?.key })
            currentFilter ? getTender(
                currentFilter?.filter,
                {
                    page: 1,
                    pageSize: tendersPagination.pageSize,
                },
                    primaryFilter,
                false,
                ) : filters.push({ key: location?.key, filter})
        }
    }, [location]);

    useEffect(() => {
        const { titleKeyword = '', naicsCodes = [], pscCodes = [], buyersInclude = [], buyersExclude = [], setAside: filterSetAsideCodes = [], noticeType: filterNoticeTypes = [] } = filter;
        // @ts-ignore
        const setAside: string[] = filterSetAsideCodes.map((item: string) => _.findLast(setAsideCodes, { name: item })?.id).filter((item) => item);
        // @ts-ignore
        const noticeType: string[] = filterNoticeTypes.map((item: string) => _.findLast(noticeTypes, { name: item })?.id).filter((item) => item);
        setSearch(titleKeyword)
        setBuyersInclude(buyersInclude);
        setBuyersExclude(buyersExclude);
        setNAICSCodes(naicsCodes);
        setPSCCodes(pscCodes);
        form.resetFields();
        form.setFieldsValue({
            ...filter,
            setAside,
            noticeType,
        });
    }, [filter, setAsideCodes, noticeTypes]);

    const initialAPI = async () => {
        await getNoticeTypes();
        await getSetAsideCodes();
    }

    const getSetAsideCodes = () => axios.post(TENDER_SET_ASIDE_CODE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: setAsideCodes = [], total} } = response
        setSetAsideCodes(setAsideCodes)
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getNoticeTypes = () => axios.post(TENDER_NOTICE_TYPE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: { id: string, name: string }[],
            total: number,
        }}) => {
        const { data: { items: noticeTypes = [], total} } = response
        setNoticeTypes(noticeTypes);
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const getClassifications = () => axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then((response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], buyersInclude = [], buyersExclude = [], setAsideCodes = [], opportunities = [], noticeTypes = []} } = response
        const postedDate: [Dayjs, Dayjs] = [dayjs().subtract(1, 'day'), dayjs()];
        getTender({
                buyersInclude,
                buyersExclude,
                naicsCodes,
                pscCodes,
                setAside: setAsideCodes,
                source: opportunities,
                noticeType: noticeTypes,
                postedDate,
            },
            {
                page: 1,
                pageSize: tendersPagination.pageSize,
            },
            {
                expired: true,
                eligible: true,
            },
        ).then(response => response)
    }).catch((error: AxiosError) => {
        const postedDate: [Dayjs, Dayjs] = [dayjs().subtract(1, 'day'), dayjs()];
        getTender({
            postedDate,
        },
            {
                page: 1,
                pageSize: tendersPagination.pageSize,
            },
            {
                expired: true,
                eligible: true,
            },
            ).then(response => response)
    })

    const getTender = async (
        filter: Filter,
        params = {
            page: 1,
            pageSize: tendersPagination.pageSize,
        },
        primaryFilter: PrimaryFilter,
        changeNavigate = true,
        ) => {
        const body: Filter = {}
        const { titleKeyword, source, active, inactive, dueDate, postedDate, solicitationNumber, buyersInclude, buyersExclude, naicsCodes, pscCodes, noticeType = [], setAside = [] } = filter;
        if (titleKeyword) body.titleKeyword = titleKeyword
        if (source) body.source = source
        if (active) body.status = 'active'
        if (inactive) body.status = 'inactive'
        if (dueDate && !_.isEmpty(dueDate)) body.dueDate = [dayjs(dueDate[0]).format('YYYY-MM-DD'), dayjs(dueDate[1]).format('YYYY-MM-DD')]
        if (postedDate && !_.isEmpty(postedDate)) body.publishDate = [dayjs(postedDate[0]).format('YYYY-MM-DD'), dayjs(postedDate[1]).format('YYYY-MM-DD')]
        if (solicitationNumber) body.solicitationNumber = solicitationNumber
        if (!_.isEmpty(buyersInclude)) body.agency = buyersInclude
        if (!_.isEmpty(buyersExclude)) body.agencyExclude = buyersExclude
        if (!_.isEmpty(naicsCodes)) body.naicsCode = naicsCodes
        if (!_.isEmpty(pscCodes)) body.pscCode = pscCodes
        if (!_.isEmpty(noticeType)) body.noticeType = noticeType;
        if (!_.isEmpty(setAside)) body.setAside = setAside.map((item: string) => (item === 'Without Set Aside Code') ? 'null' : item);
        dispatch({ type: SET_FILTER, payload: { filter: {
                    ...filter,
                    noticeType,
                    setAside,
                }}})
        dispatch({ type: SET_PRIMARY_FILTER, payload: { primaryFilter }})
        changeNavigate && navigate('/opportunities')
        setLoadingTender(true)
        return axios.post(TENDER_TENDER_PAGINATE, { ...body, ...primaryFilter }, { params: { page: params.page - 1, size: params.pageSize } }).then((response: { data: {
                items: Tender[],
                total: number,
            }}) => {
            const { data: { items: tenders, total} } = response
            dispatch({ type: SET_TENDERS, payload: { tenders }})
            dispatch({ type: SET_TENDERS_PAGINATION, payload: { tendersPagination: {
                        ...params,
                        total,
                    }}})
            setLoadingTender(false)
        }).catch((error: AxiosError) => {
            const { response: { data } } = error;
            dispatch({ type: SET_TENDERS, payload: { tenders: [] }})
            dispatch({ type: SET_TENDERS_PAGINATION, payload: { tendersPagination: {
                        page: 1,
                        pageSize: 6,
                        total: 0,
                    }}})
            setLoadingTender(false)
            // @ts-ignore
            message.error(MessageLanguage[data.message]||data.message).then(response => response )
        })
    }

    const setBuyerInclude = (buyerInclude: string) => {
        if (buyerInclude) {
            setBuyersInclude(_.uniq([...buyersInclude, buyerInclude]));
            form.resetFields(['buyerInclude']);
        }
        setBuyerIncludeOptions([])
    }

    const setBuyerExclude = (buyerExclude: string) => {
        if (buyerExclude) {
            setBuyersExclude(_.uniq([...buyersExclude, buyerExclude]));
            form.resetFields(['buyerExclude']);
        }
        setBuyerExcludeOptions([])
    }

    const setNAICSCode = (naicsCode: string) => {
        if (naicsCode && naicsCode.match(isNumber)) {
            setNAICSCodes(_.uniq([...naicsCodes, naicsCode]));
            form.resetFields(['naicsCode']);
        }
        setNAICSCodeOptions([])
    }

    const setPSCCode = (pscCode: string) => {
        if (pscCode) {
            setPSCCodes(_.uniq([...pscCodes, pscCode]));
            form.resetFields(['pscCode']);
        }
        setPSCCodeOptions([])
    }

    const like = (tenderId: string) =>  axios.get(TENDER_TENDER_LIKE + tenderId).then(async () => {
        const { page, pageSize} = tendersPagination;
        await getTender(filter,
            {
                page,
                pageSize,
            },
            primaryFilter,
            )
    })

    const dislike = (tenderId: string) =>  axios.get(TENDER_TENDER_DISLIKE + tenderId).then(async () => {
        const { page, pageSize} = tendersPagination;
        await getTender(filter,
            {
                page,
                pageSize,
            },
            primaryFilter,
            )
    })

    const bookmark = (tenderId: string) =>  axios.get(TENDER_TENDER_BOOKMARK + tenderId).then(async () => {
        const { page, pageSize} = tendersPagination;
        await getTender(filter,
            {
                page,
                pageSize,
            },
            primaryFilter,
            )
    })

    const onFinish = (async (values: Filter) => {
        const { noticeType, setAside } = values;
        // @ts-ignore
        const valuesNoticeType: string[] = noticeType.map((item: string) => _.findLast(noticeTypes, { id: item })?.name).filter((item) => item);
        // @ts-ignore
        const valuesSetAside: string[] = setAside.map((item: string) => _.findLast(setAsideCodes, { id: item })?.name).filter((item) => item);

        await getTender({
            ...values,
            noticeType: valuesNoticeType,
            setAside: valuesSetAside,
            buyersInclude,
            buyersExclude,
            naicsCodes,
            pscCodes,
        }, {
            page: 1,
            pageSize: tendersPagination.pageSize,
        },
            {
                expired: false,
                eligible: false,
            }
            )
    })

    return (
        <div className={'opportunities'}>
            <Helmet>
                <title>{title} - {Opportunities.title}</title>
            </Helmet>
            <Row>
                <Col xs={24} sm={24} lg={24} className={showFilter ? 'sider-left-border' : ''} xl={showFilter ? 20 : 24}>
                    <div className={'search'}>
                        <Space direction={'vertical'} size={'large'} className={'search-form'}>
                            <Input placeholder={Opportunities.Search.searchPlaceholder} size="large" value={search} onChange={({ target: { value }}) => {
                                setSearch(value)
                                form.setFieldValue('titleKeyword', value)
                            }}
                                   onKeyPress={({ charCode }) => {
                                       if (charCode === 13) {
                                           setShowFilter(true)
                                           form.submit()
                                       }
                                   }}
                                   prefix={
                                       <Button
                                           loading={loadingTender}
                                           type={'text'}
                                           icon={<FaSearch size={26} />}
                                           onClick={() => {
                                               setShowFilter(true)
                                               form.submit()
                                           }}
                                       />
                                   } />
                            <Space className={'search-button'}>
                                <Button type="primary"><Space><span>{Opportunities.Search.createSchedule}</span><FaArrowCircleRight size={15} /></Space></Button>
                                <Button type="primary" onClick={async () => {
                                    await getClassifications()
                                    setShowFilter(true)
                                }}><Space><span>{Opportunities.Filter.useProfileFilter}</span><FaFilter size={15} /></Space></Button>
                                <Button type="primary" onClick={() => setShowFilter(true)}><Space><span>{Opportunities.Search.filter}</span></Space></Button>
                            </Space>
                        </Space>
                        {
                            !_.isEmpty(tenders) ? (
                                <>
                                    <Row gutter={20} className={'search-result'}>
                                        {
                                            tenders.map((tender: Tender) => {
                                                return (
                                                    <Col xs={24} sm={24} lg={24} xl={12}>
                                                        <Card title={(
                                                            <Space direction={"vertical"} size={25}>
                                                                <Space>
                                                                    <Link
                                                                        className={'title ellipsis'}
                                                                        to={'/opportunities/tender/' + tender?.id}
                                                                    >{tender?.title}</Link>
                                                                    <Tooltip title={Opportunities.Filter.copyClipboard}><FaLink
                                                                        size={17} style={{cursor: "pointer"}}
                                                                        onClick={async () => {
                                                                            await window?.navigator?.clipboard?.writeText(siteURL + 'opportunities/tender/' + tender?.id);
                                                                            message.success(MessageLanguage[Message.COPY_TO_CLIPBOARD]).then(response => response)
                                                                        }}/></Tooltip>
                                                                    {tender?.readableFiles === 0 && <Tooltip
                                                                        title={Opportunities.Filter.noReadableFiles}><FaFileAlt
                                                                        size={20} color={'#f64646'}/></Tooltip>}
                                                                    {tender?.detailedViewStringify && <Tooltip
                                                                        title={Opportunities.Filter.precompiled}><FaFolderOpen
                                                                        size={20}/></Tooltip>}
                                                                    {tender?.analysisStringify && <Tooltip
                                                                        title={Opportunities.Filter.analysis}><FaChartPie
                                                                        size={20}/></Tooltip>}
                                                                </Space>
                                                                <Typography.Title
                                                                    level={2}>{Opportunities.Filter.solicitationNumber}: <span>{tender?.solicitationNumber}</span></Typography.Title>
                                                                <Space size={10}>
                                                                    <Tag
                                                                        color="#E8ECEF">{Opportunities.Search.publishDate}: {tender?.publishDate && dayjs(tender?.publishDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')}</Tag>
                                                                    <Tag
                                                                        color="#E8ECEF">{Opportunities.Search.dueDate} {tender?.dueDate && dayjs(tender?.dueDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY') + ' RM EST Time ' + dayjs(tender?.dueDate).tz(process.env.REACT_APP_TZ).format('hh:mm')}</Tag>
                                                                    {/*<Tag color="#E8ECEF">{Opportunities.Search.pvb}: {PVB[(Math.floor(Math.random() * PVB.length))]}</Tag>*/}
                                                                    {/*<Tag color="#E8ECEF">{Opportunities.Search.pts}: {PTS[(Math.floor(Math.random() * PTS.length))]}</Tag>*/}
                                                                </Space>
                                                                <Space direction={"vertical"} size={0}>
                                                                    <div
                                                                        className={'description'}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: tender?.description,
                                                                        }}/>
                                                                    <b>...</b>
                                                                </Space>
                                                            </Space>
                                                        )}>
                                                            <Space direction={"vertical"} size={20}
                                                                   style={{width: '100%'}}>
                                                                <Space className={'search-tag'} key={'tag'}>
                                                                <Typography.Paragraph>
                                                                        <Tooltip key={'buyer'} placement="top" title={`${Opportunities.Search.buyer}: ${tender?.agency?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.buyer}: {tender?.agency?.toLowerCase()}</span></Tag></Tooltip>
                                                                        <Tooltip key={'naics'} placement="top" title={`${Opportunities.Search.naics}: ${tender?.naicsCode?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.naics}: {tender?.naicsCode?.toLowerCase()}</span></Tag></Tooltip>
                                                                        <Tooltip key={'notice-type'} placement="top" title={`${Opportunities.Search.noticeType}: ${_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.noticeType}: {_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}</span></Tag></Tooltip>
                                                                        <Tooltip key={'psc'} placement="top" title={`${Opportunities.Search.psc}: ${_.isArray(tender?.pscCode) ? tender?.pscCode?.join(", ")?.toLowerCase() : tender?.pscCode?.toLowerCase() }`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.psc}: {_.isArray(tender?.pscCode) ? tender?.pscCode?.join(", ")?.toLowerCase() : tender?.pscCode?.toLowerCase() }</span></Tag></Tooltip>
                                                                        <Tooltip key={'set-aside'} placement="top" title={`${Opportunities.Search.setAside}: ${_.isArray(tender?.setAside) ? !_.isEmpty(tender?.setAside) ? tender?.setAside?.join(", ")?.toLowerCase() : Opportunities.Search.withoutSetAside.toLowerCase() :  (tender?.setAside?.toLowerCase() || Opportunities.Search.withoutSetAside.toLowerCase())}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.setAside}: {_.isArray(tender?.setAside) ? !_.isEmpty(tender?.setAside) ? tender?.setAside?.join(", ")?.toLowerCase() : Opportunities.Search.withoutSetAside.toLowerCase() : (tender?.setAside?.toLowerCase() || Opportunities.Search.withoutSetAside.toLowerCase())}</span></Tag></Tooltip>
                                                                    </Typography.Paragraph>
                                                                </Space>
                                                                <Flex justify={'space-between'} style={{marginTop:10}}>
                                                                    <Space className={'save-and-like'} size={20}>
                                                                        {tender?.bookmark ? <FaBookmark size={22} onClick={() => bookmark(tender?.id)} /> : <FaRegBookmark size={22} onClick={() => bookmark(tender?.id)} />}
                                                                        {tender?.like === true ?  <FaThumbsUp size={22} onClick={() => like(tender?.id)} /> :  <FaRegThumbsUp size={22} onClick={() => like(tender?.id)} />}
                                                                        {tender?.like === false ? <FaThumbsDown size={22} onClick={() => dislike(tender?.id)} /> : <FaRegThumbsDown size={22} onClick={() => dislike(tender?.id)} />}
                                                                    </Space>
                                                                    <Button type={'primary'} onClick={() => navigate('/opportunities/tender/' + tender?.id)}><Space><span>{Opportunities.Search.selectOpportunity}</span></Space></Button>
                                                                </Flex>
                                                            </Space>
                                                        </Card>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                    <Flex justify={'center'}>
                                        <Pagination
                                            defaultCurrent={tendersPagination.page}
                                            current={tendersPagination.page}
                                            pageSize={tendersPagination.pageSize}
                                            showSizeChanger={false}
                                            total={tendersPagination.total}
                                            itemRender={ (_, type, originalElement) => {
                                                if (type === 'prev') {
                                                    return <a>{Share.prev}</a>;
                                                }
                                                if (type === 'next') {
                                                    return <a>{Share.next}</a>;
                                                }
                                                return originalElement;
                                            }}
                                            onChange={async (page, pageSize) => {
                                                await getTender(filter,
                                                    {
                                                        page,
                                                        pageSize,
                                                    },
                                                    primaryFilter,
                                                )
                                            }}
                                        />
                                    </Flex>
                                </>
                            ) : <Empty description={Share.noResults} />
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={4} style={{display: showFilter ? 'block' : 'none'}}>
                    <Affix>
                        <div className={'filter'}>
                            <div className={'title'}>
                                <Flex justify={'space-between'}>
                                    <span>{Opportunities.Filter.title}</span>
                                    <FaTimes size={18} onClick={() => setShowFilter(false)} />
                                </Flex>
                            </div>
                            <Space direction={'vertical'} className={'filter-item'} size={20}>
                                <Form layout="vertical" form={form} name="filter-opportunities" onFinish={onFinish}>
                                    <Form.Item name="titleKeyword" style={{display:'none'}} initialValue={search}>
                                        <Input type={'hidden'} />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.source}
                                        name="source"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            size={'large'}
                                            mode="multiple"
                                            placeholder={Share.pleaseSelect}
                                            optionFilterProp="label"
                                            options={sources.map(({ id, name, color, key }) => ({ label: <Space size={10}><div style={{width:10,height:10,borderRadius:3,backgroundColor:color}}></div><span>{name}</span></Space>, value: key }))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="active"
                                        valuePropName="checked"
                                        dependencies={['inactive']}
                                        initialValue={false}
                                        style={{marginBottom:5}}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('inactive') && form.setFieldValue('inactive', false)}>{Opportunities.Filter.activeOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="inactive"
                                        valuePropName="checked"
                                        dependencies={['active']}
                                        initialValue={false}
                                    >
                                        <Checkbox onChange={({ target: { checked} }) => checked && form.getFieldValue('active') && form.setFieldValue('active', false)}>{Opportunities.Filter.inactiveOnly}</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.dueDate}
                                        name="dueDate"
                                    >
                                        <DatePicker.RangePicker
                                            allowClear={true}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.postedDate}
                                        name="postedDate"
                                    >
                                        <DatePicker.RangePicker
                                            allowClear={true}
                                            size={'large'}
                                            format="YYYY-MM-DD"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.solicitationNumber}
                                        name="solicitationNumber"
                                    >
                                        <Input
                                            placeholder={Opportunities.Filter.solicitationNumberPlaceholder}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyerInclude}
                                        name="buyerInclude"
                                        extra={!_.isEmpty(buyersInclude) && (
                                            <div className={'tags'}>
                                                {buyersInclude.map((buyerInclude: string) => <Tooltip key={buyerInclude} placement="top" title={buyerInclude}><Tag color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                                    const buyersIncludeCopy = [...buyersInclude]
                                                    buyersIncludeCopy.splice(buyersIncludeCopy.indexOf(buyerInclude), 1)
                                                    setBuyersInclude(buyersIncludeCopy)
                                                }}><span className={'ellipsis'}>{buyerInclude}</span></Tag></Tooltip>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerIncludeOptions.map(({ name }) => ({ label: (
                                                    <Space direction={'vertical'} size={0}>
                                                        <Typography.Title level={3}>{name}</Typography.Title>
                                                    </Space>
                                                ), value: name }))}
                                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (name) setBuyerIncludeOptions(items.map(({ name}) => ({ name })))
                                                else setBuyerIncludeOptions([])
                                            }).catch( (error: {}) => setBuyerIncludeOptions([]) )}
                                            onSelect={(buyerInclude: string) => setBuyerInclude(buyerInclude)}
                                            onBlur={({ target: { value: buyerInclude } }: any) => setBuyerInclude(buyerInclude)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.buyerExclude}
                                        name="buyerExclude"
                                        extra={!_.isEmpty(buyersExclude) && (
                                            <div className={'tags'}>
                                                {buyersExclude.map((buyerExclude: string) => <Tooltip placement="top" key={buyerExclude} title={buyerExclude}><Tag color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                                    const buyersExcludeCopy = [...buyersExclude]
                                                    buyersExcludeCopy.splice(buyersExcludeCopy.indexOf(buyerExclude), 1)
                                                    setBuyersExclude(buyersExcludeCopy)
                                                }}><span className={'ellipsis'}>{buyerExclude}</span></Tag></Tooltip>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.buyerPlaceholder}
                                            size="large"
                                            options={buyerExcludeOptions.map(({ name }) => ({ label: (
                                                    <Space direction={'vertical'} size={0}>
                                                        <Typography.Title level={3}>{name}</Typography.Title>
                                                    </Space>
                                                ), value: name }))}
                                            onSearch={(name) => axios.post(TENDER_BUYER_PAGINATE, { name }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { name: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (name) setBuyerExcludeOptions(items.map(({ name }) => ({ name })))
                                                else setBuyerExcludeOptions([])
                                            }).catch( (error: {}) => setBuyerExcludeOptions([]) )}
                                            onSelect={(buyerExclude: string) => setBuyerExclude(buyerExclude)}
                                            onBlur={({ target: { value: buyerExclude } }: any) => setBuyerExclude(buyerExclude)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.naicsCode}
                                        name="naicsCode"
                                        rules={[
                                            { pattern: isNumber, message: `${Opportunities.Filter.naicsCode} ${Validation.number}` },
                                        ]}
                                        extra={!_.isEmpty(naicsCodes) && (
                                            <div className={'tags'}>
                                                {naicsCodes.map((naicsCode: string) => <Tag key={naicsCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                                    const naicsCodesCopy = [...naicsCodes]
                                                    naicsCodesCopy.splice(naicsCodesCopy.indexOf(naicsCode), 1)
                                                    setNAICSCodes(naicsCodesCopy)
                                                }}>{naicsCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.naicsCodePlaceholder}
                                            size="large"
                                            options={naicsCodeOptions}
                                            onSearch={(code) => axios.post(TENDER_NAICS_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (code) setNAICSCodeOptions(items.map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                                else setNAICSCodeOptions([])
                                            }).catch( (error: {}) => setNAICSCodeOptions([]) )}
                                            onSelect={(naicsCode: string) => setNAICSCode(naicsCode)}
                                            onBlur={({ target: { value: naicsCode } }: any) => setNAICSCode(naicsCode)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.pscCode}
                                        name="pscCode"
                                        extra={!_.isEmpty(pscCodes) && (
                                            <div className={'tags'}>
                                                {pscCodes.map((pscCode: string) => <Tag key={pscCode} color="#e1e6e8" closable onClose={(event: MouseEvent) => {
                                                    const pscCodesCopy = [...pscCodes]
                                                    pscCodesCopy.splice(pscCodesCopy.indexOf(pscCode), 1)
                                                    setPSCCodes(pscCodesCopy)
                                                }}>{pscCode}</Tag>)}
                                            </div>
                                        )}
                                    >
                                        <AutoComplete
                                            placeholder={Opportunities.Filter.pscCodePlaceholder}
                                            size={'large'}
                                            options={pscCodeOptions}
                                            onSearch={(code) => axios.post(TENDER_PSC_CODE_PAGINATE, { code }, { params: { page: 0, size: 10, loading: false } }).then((response: { data: { items: { code: string, title: string }[], total: number } }) => {
                                                const { data: { items, total } } = response
                                                if (code) setPSCCodeOptions(_.unionBy(items, 'code').map(({ code, title }, key) => ({ label: `${code} - ${title}`, value: code, key })))
                                                else setPSCCodeOptions([])
                                            }).catch( (error: {}) => setPSCCodeOptions([]) )}
                                            onSelect={(pscCode: string) => setPSCCode(pscCode)}
                                            onBlur={({ target: { value: pscCode } }: any) => setPSCCode(pscCode)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.noticeType}
                                        name="noticeType"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.noticeTypePlaceholder}
                                            optionFilterProp="label"
                                            options={noticeTypes.map(({ id, name }) => ({ label: name, value: id }))}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={Opportunities.Filter.setAsideCode}
                                        name="setAside"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            size={'large'}
                                            placeholder={Opportunities.Filter.setAsideCodePlaceholder}
                                            optionFilterProp="label"
                                            options={setAsideCodes.map(({ id, name }) => ({ label: name, value: id }))}
                                        />
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                block={true}
                                                loading={loadingTender}
                                            >
                                                {Opportunities.Filter.applyFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                    <Form.Item shouldUpdate>
                                        {() => (
                                            <Button
                                                type="link"
                                                block={true}
                                                loading={loadingTender}
                                                className={'link-button'}
                                                onClick={async () => {
                                                    setSearch('')
                                                    setPSCCodeOptions([])
                                                    setBuyerIncludeOptions([])
                                                    setBuyersInclude([])
                                                    setBuyerExcludeOptions([])
                                                    setBuyersExclude([])
                                                    setNAICSCodeOptions([])
                                                    setNAICSCodes([])
                                                    setPSCCodes([])
                                                    form.resetFields()
                                                    await getTender({
                                                            buyersInclude: [],
                                                            buyersExclude: [],
                                                            naicsCodes: [],
                                                            pscCodes: [],
                                                        },
                                                        {
                                                            page: 1,
                                                            pageSize: tendersPagination.pageSize,
                                                        },
                                                        {
                                                            expired: false,
                                                            eligible: false,
                                                        },
                                                    )
                                                }}
                                            >
                                                {Opportunities.Filter.clearFilter}
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Form>
                            </Space>
                        </div>
                    </Affix>
                </Col>
            </Row>
        </div>
    )
}
