import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { App, Typography, Space, Button, Row, Col, Table } from 'antd'
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { API, Enum } from "../../resources/constants";
import language from "../../resources/languages/en_US"

// Interfaces
import { AxiosError } from "../../interfaces/axios"
import {ColumnsType} from "antd/es/table";
import { Payment } from "../../interfaces/pages/profile";

// Contexts
import AxiosContext from "../../contexts/axios";
import GlobalContext from "../../contexts/global";

const { App: { title }, Pages: { Dashboard: DashboardLanguage  }, Share, Validation,  Enum: EnumLanguage, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_INVOICE_ALL } = API
const { Message } = Enum

export default function Dashboard() {

    const [paymentHistories, setPaymentHistories] = useState<Payment[]>([])
    const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
    const { axios } = useContext(AxiosContext)
    const { tendersCount } = useContext(GlobalContext)
    const { message } = App.useApp()
    const navigate = useNavigate()

    const columns: ColumnsType<Payment> = [
        {
            title: DashboardLanguage.plan,
            dataIndex: 'plan',
            key: 'plan',
            // @ts-ignore
            render: (text, record) => EnumLanguage.Plan[text]
        },
        {
            title: DashboardLanguage.activation,
            dataIndex: 'activationAt',
            key: 'activationAt',
            render: (text, record) => text && dayjs(text).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')
        },
        {
            title: DashboardLanguage.amount,
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => text && (
                <Space size={10} align={'baseline'}>
                    <FaCheckCircle color={'#23d145'} size={16} />
                    <b>{Share.usd}</b>
                    <span>{(text/100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                </Space>
            )
        },
        {
            title: DashboardLanguage.expiration,
            dataIndex: 'expirationAt',
            key: 'expirationAt',
            render: (text, record) => text && dayjs(text).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')
        },
    ]

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getPaymentHistory()
    }

    const getPaymentHistory = async () => {
        setLoadingRecords(true)
        axios.get(USER_MANAGEMENT_PROFILE_INVOICE_ALL).then((response: { data: Payment[] }) => {
            const { data: paymentHistories } = response
            setPaymentHistories(paymentHistories)
            setLoadingRecords(false)
        }, async (error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]).then(response => response )
            setLoadingRecords(false)
        })
    }

    return (
        <div className={'dashboard'}>
            <Helmet>
                <title>{title} - {DashboardLanguage.title}</title>
            </Helmet>
            <Space direction="vertical" size={25} style={{width:'100%'}}>
            <Space className={'title'} direction="vertical" size={30}>
                <Typography.Title level={1}>{DashboardLanguage.title}</Typography.Title>
                <Typography.Title level={2}>{DashboardLanguage.description}</Typography.Title>
                <Space size={30}>
                    <Button type={'primary'}>{DashboardLanguage.tenders}</Button>
                    <Button type={'text'} style={{cursor:'default'}}>{DashboardLanguage.grants}</Button>
                </Space>
            </Space>
            <Typography.Title level={3}>{DashboardLanguage.todayTenders}</Typography.Title>
            <Row gutter={50}>
                <Col xs={24} sm={24} lg={24} xl={8}>
                    <div
                        key={'usa'}
                        className={'tender'}
                        onClick={() => navigate({
                        pathname: '/opportunities',
                        search: `?source=US_TENDER`,
                    })}>
                        <Space size={20}>
                            <div className={'flag'}>
                                <img alt={DashboardLanguage.usTenders} src={require('../../resources/media/flag/usa.jpg')} width={30} height={23} />
                            </div>
                            <Typography.Title level={4}>{DashboardLanguage.usTenders}</Typography.Title>
                            { tendersCount > 0 &&  <Button type={'primary'}>{tendersCount} {Share.new}</Button> }
                        </Space>
                        <FaArrowRight size={26} />
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={8}>
                    <div
                        key={'canada'}
                        className={'tender'}
                        style={{cursor:'default'}}
                        // onClick={() => navigate("/opportunities")}
                    >
                        <Space size={20}>
                            <div className={'flag'}>
                                <img alt={DashboardLanguage.canadaTenders} src={require('../../resources/media/flag/canada.jpg')} width={30} height={23} />
                            </div>
                            <Typography.Title level={4}>{DashboardLanguage.canadaTenders}</Typography.Title>
                        </Space>
                        <FaArrowRight size={26} />
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={24} xl={8}>
                    <div
                        key={'europe'}
                        className={'tender'}
                        style={{cursor:'default'}}
                        // onClick={() => navigate("/opportunities")}
                    >
                        <Space size={20}>
                            <div className={'flag'}>
                                <img alt={DashboardLanguage.europeTenders} src={require('../../resources/media/flag/europe.jpg')} width={30} height={23} />
                            </div>
                            <Typography.Title level={4}>{DashboardLanguage.europeTenders}</Typography.Title>
                        </Space>
                        <FaArrowRight size={26} />
                    </div>
                </Col>
            </Row>
                <Typography.Title level={3} style={{marginTop:30}}>{DashboardLanguage.payments}</Typography.Title>
                <Table
                    columns={columns}
                    dataSource={paymentHistories}
                    pagination={false}
                    loading={loadingRecords}
                    scroll={{ x: true }}
                />
            </Space>
        </div>
    )
}
