// Definition of AntDesign Constants

export default {
    theme: {
        token: {
            colorPrimary: '#0084ff',
            fontFamily: 'Inter'
        },
    },
};
