import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate, Link } from "react-router-dom"
import { Helmet } from 'react-helmet'
import { Row, Col, Button, Space, Pagination, Card, Flex, Typography, Tag, Empty, App, Tooltip } from 'antd'
import {
    FaRegThumbsUp,
    FaThumbsUp,
    FaRegThumbsDown,
    FaThumbsDown,
    FaRegBookmark,
    FaBookmark,
    FaLink,
} from "react-icons/fa";
import dayjs, { Dayjs } from 'dayjs'
import { API, Enum } from "../../resources/constants"
import language from "../../resources/languages/en_US"

// Interfaces
import { Tender } from "../../interfaces/pages/opportunities"
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios";

const { App: { title }, Pages: { Opportunities, SavedOpportunities }, Share, Validation, Message: MessageLanguage, Enum: EnumLanguage } = language
const { TENDER_TENDER_BOOKMARK_ALL, TENDER_TENDER_LIKE, TENDER_TENDER_DISLIKE, TENDER_TENDER_BOOKMARK } = API;
const { Message } = Enum
const siteURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_SITE_URL : process.env.REACT_APP_PRODUCTION_SITE_URL
const PVB = ['Max', 'Min']
const PTS = ['Max', 'Min']

export default function Index() {

    const { axios } = useContext(AxiosContext)
    const navigate = useNavigate()
    const { message } = App.useApp()
    const [tenders, setTenders] = useState<Tender[]>([])

    useEffect(() => {
        initialAPI().then(response => response)
    }, []);

    const initialAPI = async () => {
        await getTender()
    }

    const getTender = async () => axios.post(TENDER_TENDER_BOOKMARK_ALL).then((response: { data: Tender[] }) => {
        const { data: tenders } = response
        setTenders(tenders)
    }).catch((error: AxiosError) => {
        const { response: { data } } = error;
        setTenders([])
        // @ts-ignore
        message.error(MessageLanguage[data.message]||data.message).then(response => response )
    })

    const like = (tenderId: string) =>  axios.get(TENDER_TENDER_LIKE + tenderId).then(() => getTender())

    const dislike = (tenderId: string) =>  axios.get(TENDER_TENDER_DISLIKE + tenderId).then(() => getTender())

    const bookmark = (tenderId: string) =>  axios.get(TENDER_TENDER_BOOKMARK + tenderId).then(() => getTender())

    return (
        <div className={'opportunities'}>
            <Helmet>
                <title>{title} - {SavedOpportunities.title}</title>
            </Helmet>
            <div className={'search'} style={{marginTop:50}}>
                {
                    !_.isEmpty(tenders) ? (
                        <>
                            <Row gutter={20} className={'search-result'}>
                                {
                                    tenders.map((tender: Tender) => {
                                        return (
                                            <Col xs={24} sm={24} lg={24} xl={12}>
                                                <Card title={(
                                                    <Space direction={"vertical"} size={25}>
                                                        <Space>
                                                            <Link
                                                                className={'title ellipsis'}
                                                                to={'/opportunities/tender/' + tender?.id}
                                                            >{tender?.title}</Link>
                                                            <FaLink size={17} style={{cursor:"pointer"}} onClick={async () => {
                                                                await window?.navigator?.clipboard?.writeText(siteURL + 'opportunities/tender/' + tender?.id);
                                                                message.success(MessageLanguage[Message.COPY_TO_CLIPBOARD]).then(response => response)
                                                            }} />
                                                        </Space>
                                                        <Typography.Title level={2}>{Opportunities.Filter.solicitationNumber}: <span>{tender?.solicitationNumber}</span></Typography.Title>
                                                        <Space size={10}>
                                                            <Tag color="#E8ECEF">{Opportunities.Search.dueDate} {tender?.dueDate && dayjs(tender?.dueDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')}</Tag>
                                                            {/*<Tag color="#E8ECEF">{Opportunities.Search.pvb}: {PVB[(Math.floor(Math.random() * PVB.length))]}</Tag>*/}
                                                            {/*<Tag color="#E8ECEF">{Opportunities.Search.pts}: {PTS[(Math.floor(Math.random() * PTS.length))]}</Tag>*/}
                                                        </Space>
                                                        <Typography.Paragraph ellipsis={{rows:5, suffix: ""}} className={'description'}>{tender?.description}</Typography.Paragraph>
                                                    </Space>
                                                )}>
                                                    <Space direction={"vertical"} size={20} style={{width:'100%'}}>
                                                        <Space className={'search-tag'} key={'tag'}>
                                                            <Typography.Paragraph>
                                                                <Tooltip key={'buyer'} placement="top" title={`${Opportunities.Search.buyer}: ${tender?.agency?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.buyer}: {tender?.agency?.toLowerCase()}</span></Tag></Tooltip>
                                                                <Tooltip key={'naics'} placement="top" title={`${Opportunities.Search.naics}: ${tender?.naicsCode?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.naics}: {tender?.naicsCode?.toLowerCase()}</span></Tag></Tooltip>
                                                                <Tooltip key={'notice-type'} placement="top" title={`${Opportunities.Search.noticeType}: ${_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.noticeType}: {_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}</span></Tag></Tooltip>
                                                                <Tooltip key={'psc'} placement="top" title={`${Opportunities.Search.psc}: ${tender?.pscCode?.toLowerCase()}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.psc}: {tender?.pscCode?.toLowerCase()}</span></Tag></Tooltip>
                                                                <Tooltip key={'set-aside'} placement="top" title={`${Opportunities.Search.setAside}: ${_.isArray(tender?.setAside) ? !_.isEmpty(tender?.setAside) ? tender?.setAside?.join(", ")?.toLowerCase() : Opportunities.Search.withoutSetAside.toLowerCase() :  (tender?.setAside?.toLowerCase() || Opportunities.Search.withoutSetAside.toLowerCase())}`}><Tag color="#E8ECEF"><span className={'ellipsis'}>{Opportunities.Search.setAside}: {_.isArray(tender?.setAside) ? !_.isEmpty(tender?.setAside) ? tender?.setAside?.join(", ")?.toLowerCase() : Opportunities.Search.withoutSetAside.toLowerCase() : (tender?.setAside?.toLowerCase() || Opportunities.Search.withoutSetAside.toLowerCase())}</span></Tag></Tooltip>
                                                            </Typography.Paragraph>
                                                        </Space>
                                                        <Flex justify={'space-between'} style={{marginTop:10}}>
                                                            <Space className={'save-and-like'} size={20}>
                                                                {tender?.bookmark ? <FaBookmark size={22} onClick={() => bookmark(tender?.id)} /> : <FaRegBookmark size={22} onClick={() => bookmark(tender?.id)} />}
                                                                {tender?.like === true ?  <FaThumbsUp size={22} onClick={() => like(tender?.id)} /> :  <FaRegThumbsUp size={22} onClick={() => like(tender?.id)} />}
                                                                {tender?.like === false ? <FaThumbsDown size={22} onClick={() => dislike(tender?.id)} /> : <FaRegThumbsDown size={22} onClick={() => dislike(tender?.id)} />}
                                                            </Space>
                                                            <Button type={'primary'} onClick={() => navigate('/opportunities/tender/' + tender?.id)}><Space><span>{Opportunities.Search.selectOpportunity}</span></Space></Button>
                                                        </Flex>
                                                    </Space>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                            {/*<Flex justify={'center'}>*/}
                            {/*    <Pagination*/}
                            {/*        pageSize={6}*/}
                            {/*        showSizeChanger={false}*/}
                            {/*        total={tenders.length}*/}
                            {/*        itemRender={ (_, type, originalElement) => {*/}
                            {/*            if (type === 'prev') {*/}
                            {/*                return <a>{Share.prev}</a>;*/}
                            {/*            }*/}
                            {/*            if (type === 'next') {*/}
                            {/*                return <a>{Share.next}</a>;*/}
                            {/*            }*/}
                            {/*            return originalElement;*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Flex>*/}
                        </>
                    ) : <Empty description={Share.noResults} />
                }
            </div>
        </div>
    )
}
