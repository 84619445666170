import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import dayjs, { Dayjs } from 'dayjs'
import { Helmet } from "react-helmet"
import {Space, App, Button, Row, Col, Typography, Flex, Tabs, Table, Tag, Divider, Spin, Tooltip} from 'antd'
import { FaChevronRight, FaMagic, FaCalendarPlus, FaCreditCard, FaCube, FaRegClock, FaRegUserCircle, FaRegFlag, FaInfoCircle, FaSuitcase, FaRegFolderOpen, FaSitemap } from "react-icons/fa"
import { useParams } from "react-router-dom"
import _ from 'lodash'
import { API, Enum } from "../../../resources/constants"
import language from "../../../resources/languages/en_US"

// Interfaces
import { Tender as TenderType } from "../../../interfaces/pages/opportunities"
import { AxiosError } from "../../../interfaces/axios";

// Contexts
import AxiosContext from "../../../contexts/axios";
import GlobalContext from "../../../contexts/global";

const { App: { title }, Pages: { Opportunities: { Tender } }, Share, Validation, Message: MessageLanguage, Enum: EnumLanguage } = language
const { TENDER_TENDER_ID, TENDER_TENDER_DOWNLOAD_FILE } = API;
const { Type } = Enum;
const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL

export default function Index() {

    const { axios } = useContext(AxiosContext)
    const { accessToken } = useContext(GlobalContext)
    const [, forceUpdate] = useState({})
    const [loadingTender, setLoadingTender] = useState<boolean>(true)
    const [tender, setTender] = useState<TenderType>()
    const [expireDays, setExpireDays] = useState<number|null>(null)
    const { id: tenderId } = useParams()
    const navigate = useNavigate()
    const { message } = App.useApp();

    useEffect(() => {
        // To disable submit button at the beginning.
        forceUpdate({})
        initialAPI().then(response => response)
    }, [])

    const initialAPI = async () => {
        tenderId && await getTender(tenderId)
    }

    const getTender = (tenderId: string) => {
        setLoadingTender(true)
        return axios.get(TENDER_TENDER_ID + tenderId, { params: { loading: false } }).then((response: { data: TenderType }) => {
            const { data: tender } = response;
            setTender(tender)
            if (tender.dueDate) {
                const days = dayjs(tender.dueDate).tz(process.env.REACT_APP_TZ).diff(new Date(), 'day')
                setExpireDays(days > 0 ? days : 0)
            }
            setLoadingTender(false)
        }).catch((error: AxiosError) => {
            const { response: { data } } = error;
            // @ts-ignore
            message.error(MessageLanguage[data.message]||data.message).then(response => response )
            setLoadingTender(false)
        }).finally(() => setLoadingTender(false))
    }

    return (
        <div className={'tender'}>
            <Helmet>
                {tender?.title ? <title>{title} - {tender?.title}</title> : <title>{title}</title>}
            </Helmet>
            <Spin size={'large'} spinning={loadingTender} tip={<b style={{color:'#8f8f8f'}}>{Tender.loadingTender}</b>}>
                <Space direction={'vertical'} size={30} style={{width:'100%'}}>
                    <Space className={'buttons-left'} style={{width:'100%'}}>
                        <Button type={'text'} icon={<FaChevronRight size={16} />} className={'button-grey'} onClick={() => navigate({
                            pathname: '/opportunities',
                            // search: `?source=${tender?.type || Type.US_Tenders}`,
                        })} >
                            {
                                // @ts-ignore
                                tender?.type ? EnumLanguage.Type[tender.type] : EnumLanguage.Type[Type.US_Tenders]
                            }
                        </Button>
                        <Button type={'primary'} icon={<FaChevronRight size={16} />} onClick={() => initialAPI().then(response => response)} >{tender?.title}</Button>
                    </Space>
                    <Space className={'buttons-right'} style={{width:'100%'}}>
                        <Button type={'text'} icon={<FaMagic size={16} />} className={'button-black shine'} style={{cursor:'default'}}>
                            {Tender.askAlpha}
                        </Button>
                        <Button type={'primary'} icon={<FaCalendarPlus size={16} />} style={{cursor:'default'}}>
                            {Tender.addToCRM}
                        </Button>
                        <Button type={'text'} icon={<FaCreditCard size={16} />} className={'button-red'} style={{cursor:'default'}}>
                            {Tender.onDemandCredit}
                        </Button>
                    </Space>
                    <Row gutter={20}>
                        {/*<Col xs={24} sm={24} lg={24} xl={4}>*/}
                        {/*    <div className={'ask-history'}>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col xs={24} sm={24} lg={24} xl={24}>
                            <div className={'tender-details'}>
                                <Flex gap={'middle'} justify={'space-between'} style={{marginTop:20,marginBottom:30}}>
                                    <Space align={'baseline'}>
                                        <Typography.Title level={1} style={{marginRight:50}}>{tender?.title}</Typography.Title>
                                        {
                                            expireDays ? (
                                                <>
                                                    <Button type={'text'} size={'large'} className={'button-green'}>{Tender.active}</Button>
                                                    <Button type={'text'} size={'large'} className={'button-grey'}>{expireDays} {Tender.days}</Button>
                                                </>
                                            ) : expireDays !== null ? (
                                                <Button type={'text'} size={'large'} className={'button-grey'}>{Tender.expired}</Button>
                                            ) : null
                                        }
                                    </Space>
                                    <Typography.Title level={2}>{Tender.solicitationNumber}: {tender?.solicitationNumber}</Typography.Title>
                                </Flex>
                                <Tabs defaultActiveKey="overview" style={{marginBottom:30}}>
                                    <Tabs.TabPane key="overview" tab={Tender.overview} icon={<FaCube />}>
                                        <Space direction={'vertical'} size={2}>
                                            <Typography.Title
                                                level={3}>{Tender.projectScopeWorkService}</Typography.Title>
                                            <Space key={'tag'}>
                                                <Typography.Paragraph>
                                                    <Tag
                                                        color="#E8ECEF">{Tender.publishDate}: {tender?.publishDate && dayjs(tender?.publishDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY')}</Tag>
                                                    <Tag
                                                        color="#E8ECEF">{Tender.dueDate} {tender?.dueDate && dayjs(tender?.dueDate).tz(process.env.REACT_APP_TZ).format('MMMM D, YYYY') + ' RM EST Time ' + dayjs(tender?.dueDate).tz(process.env.REACT_APP_TZ).format('hh:mm')}</Tag>
                                                    <Tag
                                                        color="#E8ECEF">{Tender.psc}: {_.isArray(tender?.pscCode) ? tender?.pscCode?.join(", ")?.toLowerCase() : tender?.pscCode?.toLowerCase()}</Tag>
                                                    <Tag
                                                        color="#E8ECEF">{Tender.naics}: {tender?.naicsCode?.toLowerCase()}</Tag>
                                                    <Tooltip key={'notice-type'} placement="top"
                                                             title={`${Tender.noticeType}: ${_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}`}><Tag
                                                        color="#E8ECEF"><span
                                                        className={'ellipsis'}>{Tender.noticeType}: {_.isArray(tender?.noticeType) ? tender?.noticeType?.join(", ")?.toLowerCase() : tender?.noticeType?.toLowerCase()}</span></Tag></Tooltip>
                                                </Typography.Paragraph>
                                            </Space>
                                            <Typography.Title level={3}>{Tender.description}</Typography.Title>
                                            <div
                                                className={'description'}
                                                dangerouslySetInnerHTML={{
                                                    __html: tender?.description || '',
                                                }}/>
                                            {
                                                tender?.detailedView?.overview && (
                                                    <>
                                                        <p>{tender?.detailedView?.overview?.project_scope}</p>
                                                        <Typography.Title
                                                            level={3}>{Tender.billOfMaterials}</Typography.Title>
                                                        <Table
                                                            columns={[
                                                                {
                                                                    title: Tender.item,
                                                                    dataIndex: 'item',
                                                                    key: 'item',
                                                                },
                                                                {
                                                                    title: Tender.quantity,
                                                                    dataIndex: 'quantity',
                                                                    key: 'quantity',
                                                                },
                                                                {
                                                                    title: Tender.preferredVendor,
                                                                    dataIndex: 'preferred_vendor',
                                                                    key: 'preferred_vendor',
                                                                },
                                                                {
                                                                    title: Tender.modelNumber,
                                                                    dataIndex: 'model_number',
                                                                    key: 'model_number',
                                                                },
                                                                {
                                                                    title: Tender.technicalSpecification,
                                                                    dataIndex: 'technical_specification',
                                                                    key: 'technical_specification',
                                                                }
                                                            ]}
                                                            dataSource={tender?.detailedView?.overview?.bill_of_materials}
                                                            pagination={false}
                                                            scroll={{x: true}}
                                                        />
                                                        <Typography.Title
                                                            level={3}>{Tender.vendorSelectionCriteria}</Typography.Title>
                                                        <p>
                                                            <b>{Tender.alternateVendor}:</b> {tender?.detailedView?.overview?.vendor_selection_criteria?.preferred_vendor}
                                                        </p>
                                                        <Typography.Title
                                                            level={3}>{Tender.additionalTechnicalInformation}</Typography.Title>
                                                        <p>{tender?.detailedView?.overview?.additional_technical_information}</p>
                                                    </>
                                                )
                                            }
                                        </Space>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="delivery" tab={Tender.delivery} icon={<FaRegClock/>}
                                                  disabled={!tender?.detailedView?.delivery_inspection_test}>
                                        {
                                            tender?.detailedView?.delivery_inspection_test && (
                                                <Space direction={'vertical'} size={2}>
                                                    <Typography.Title level={3}>{Tender.deliveryAddress}</Typography.Title>
                                                    <p>{tender?.detailedView?.delivery_inspection_test?.delivery_address}</p>
                                                    <Typography.Title level={3}>{Tender.placePerformance}</Typography.Title>
                                                    <p>{tender?.detailedView?.delivery_inspection_test?.place_of_performance}</p>
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="buyer" tab={Tender.buyer} icon={<FaRegUserCircle />} disabled={!tender?.detailedView?.buyer}>
                                        {
                                            tender?.detailedView?.buyer && (
                                                <Space direction={'vertical'} size={2}>
                                                    <Typography.Title level={3}>{Tender.nameAgency}</Typography.Title>
                                                    <p>{tender?.detailedView?.buyer?.agency_name}</p>
                                                    <Typography.Title level={3}>{Tender.subTierName}</Typography.Title>
                                                    <p>{tender?.detailedView?.buyer?.sub_tier_name}</p>
                                                    <Typography.Title level={3}>{Tender.officeName}</Typography.Title>
                                                    <p>{tender?.detailedView?.buyer?.office_name}</p>
                                                    <Typography.Title level={3}>{Tender.contactInformation}</Typography.Title>
                                                    <p>{tender?.detailedView?.buyer?.contact_information?.map(({ email, name, phone }) => (
                                                        <ul>
                                                            <li><b>{Tender.name}: </b><span>{name}</span></li>
                                                            <li><b>{Tender.email}: </b><span>{email}</span></li>
                                                            <li><b>{Tender.phone}: </b><span>{phone}</span></li>
                                                        </ul>
                                                    ))}</p>
                                                    <Typography.Title level={3}>{Tender.buyerWebsite}</Typography.Title>
                                                    <p>{tender?.detailedView?.buyer?.buyer_website}</p>
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="inspectionTest" tab={Tender.inspectionTest} icon={<FaRegFlag />} disabled={!tender?.detailedView?.delivery_inspection_test}>
                                        {
                                            tender?.detailedView?.delivery_inspection_test && (
                                                <Space direction={'vertical'} size={2}>
                                                    <Typography.Title level={3}>{Tender.specificTestsRequested}</Typography.Title>
                                                    <p>{tender?.detailedView?.delivery_inspection_test?.specific_tests_requested}</p>
                                                    <Typography.Title level={3}>{Tender.inspectionsRequested}</Typography.Title>
                                                    <p>{tender?.detailedView?.delivery_inspection_test?.inspections_requested}</p>
                                                    <Typography.Title level={3}>{Tender.additionalRequiredSteps}</Typography.Title>
                                                    <p>{tender?.detailedView?.delivery_inspection_test?.additional_required_steps}</p>
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="compliance" tab={Tender.compliance} icon={<FaInfoCircle />} disabled={!tender?.detailedView?.compliance}>
                                        {
                                            tender?.detailedView?.compliance && (
                                                <Space direction={'vertical'} size={10}>
                                                    <Typography.Title level={3}>{Tender.setAsideInformation}</Typography.Title>
                                                    { tender?.detailedView?.compliance?.set_aside_information && <Tag color="#E8ECEF">{tender?.detailedView?.compliance?.set_aside_information}</Tag> }
                                                    <Typography.Title level={3}>{Tender.requiredCertificates}</Typography.Title>
                                                    <ul>
                                                        {tender?.detailedView?.compliance?.required_certificates?.map((item) => <li>{item}</li>)}
                                                    </ul>
                                                    <Typography.Title level={3}>{Tender.warrantyDetails}</Typography.Title>
                                                    <p>{tender?.detailedView?.compliance?.warranty_details}</p>
                                                    <Typography.Title level={3}>{Tender.guaranteeDetails}</Typography.Title>
                                                    <p>{tender?.detailedView?.compliance?.guarantee_information}</p>
                                                    <Typography.Title level={3}>{Tender.legalClauses}</Typography.Title>
                                                    <Table
                                                        columns={[
                                                            {
                                                                title: Tender.clauseNumber,
                                                                dataIndex: 'clause_number',
                                                                key: 'clause_number',
                                                            },
                                                            {
                                                                title: Tender.clauseTitle,
                                                                dataIndex: 'clause_title',
                                                                key: 'clause_title',
                                                            },
                                                            {
                                                                title: Tender.description,
                                                                dataIndex: 'description',
                                                                key: 'description',
                                                            }
                                                        ]}
                                                        dataSource={tender?.detailedView?.compliance?.legal_clauses}
                                                        pagination={false}
                                                        scroll={{ x: true }}
                                                    />
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                    {/*<Tabs.TabPane key="commercialInsight" tab={Tender.commercialInsight} icon={<FaSuitcase />} disabled={!tender?.detailedView?.commercial_insight}>*/}
                                    {/*    {*/}
                                    {/*        tender?.detailedView?.commercial_insight && (*/}
                                    {/*            <Space direction={'vertical'} size={2}>*/}
                                    {/*                <Typography.Title level={3}>{Tender.estimatedPrice}</Typography.Title>*/}
                                    {/*                {*/}
                                    {/*                    tender?.detailedView?.commercial_insight?.whole_estimated_price && (*/}
                                    {/*                        <p>{Tender.estimatedPricePartOne} ${tender?.detailedView?.commercial_insight?.whole_estimated_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {Tender.estimatedPricePartTwo}</p>*/}
                                    {/*                    )*/}
                                    {/*                }*/}

                                    {/*            </Space>*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</Tabs.TabPane>*/}
                                    <Tabs.TabPane key="docs" tab={Tender.docs} icon={<FaRegFolderOpen />} disabled={!tender?.detailedView?.documents}>
                                        {
                                            tender?.detailedView?.documents && (
                                                <Space direction={'vertical'} size={2}>
                                                    <Typography.Title level={3}>{Tender.tenderFiles}</Typography.Title>
                                                    <ul>
                                                        {
                                                            tender?.files?.map(({ name, download }) => (download ? <li><a href={baseURL + TENDER_TENDER_DOWNLOAD_FILE + tender?.id + '/' + name + `?token=${accessToken}`} target={'_self'}>{name}</a></li> : <li>{name}</li>))
                                                        }
                                                    </ul>
                                                    <Typography.Title level={3}>{Tender.bidSubmissionFormat}</Typography.Title>
                                                    <Table
                                                        columns={[
                                                            {
                                                                title: Tender.requirement,
                                                                dataIndex: 'requirement',
                                                                key: 'requirement',
                                                            },
                                                            {
                                                                title: Tender.description,
                                                                dataIndex: 'description',
                                                                key: 'description',
                                                            }
                                                        ]}
                                                        dataSource={tender?.detailedView?.documents?.bid_submission_format}
                                                        pagination={false}
                                                        scroll={{ x: true }}
                                                    />
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                    <Tabs.TabPane key="decisionIntelligence" tab={Tender.decisionIntelligence} icon={<FaSitemap />} disabled={!tender?.decisionIntelligence?.decision_intelligence}>
                                        {
                                            tender?.decisionIntelligence?.decision_intelligence && (
                                                <Space direction={'vertical'} size={2}>
                                                    {/*<Typography.Title level={3}>{Tender.strategicAlignment}</Typography.Title>*/}
                                                    {/*{*/}
                                                    {/*    tender?.decisionIntelligence?.decision_intelligence?.strategic_alignment && (*/}
                                                    {/*        <ul>*/}
                                                    {/*            <li><b>{Tender.relevanceExpertise}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.strategic_alignment?.relevance_of_expertise}</span></li>*/}
                                                    {/*            <li><b>{Tender.valueProposition}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.strategic_alignment?.value_proposition}</span></li>*/}
                                                    {/*            <li><b>{Tender.outcomeAdvantage}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.strategic_alignment?.outcome_advantage}</span></li>*/}
                                                    {/*        </ul>*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                    {/*<Divider />*/}
                                                    <Typography.Title level={3}>{Tender.opportunityPrioritization}</Typography.Title>
                                                    {
                                                        tender?.decisionIntelligence?.decision_intelligence?.opportunity_prioritization && (
                                                            <ul>
                                                                <li><b>{Tender.setAsideOpportunity}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.opportunity_prioritization?.competitive_advantage}</span></li>
                                                                <li><b>{Tender.evaluationFocus}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.opportunity_prioritization?.relevance_to_capabilities}</span></li>
                                                                <li><b>{Tender.timelineSubmission}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.opportunity_prioritization?.timeline_highlights}</span></li>
                                                            </ul>
                                                        )
                                                    }
                                                    <Divider />
                                                    {/*<Typography.Title level={3}>{Tender.riskMitigation}</Typography.Title>*/}
                                                    {/*{*/}
                                                    {/*    tender?.decisionIntelligence?.decision_intelligence?.risk_mitigation && (*/}
                                                    {/*        <ul>*/}
                                                    {/*            <li><b>{Tender.complianceRisks}:</b></li>*/}
                                                    {/*            <ul style={{paddingLeft:10,marginBottom:15}}>*/}
                                                    {/*                { tender?.decisionIntelligence?.decision_intelligence?.risk_mitigation?.compliance_risks?.map((item) => (<li>{item}</li>))}*/}
                                                    {/*            </ul>*/}
                                                    {/*            <li><b>{Tender.deliveryRisks}:</b></li>*/}
                                                    {/*            <ul style={{paddingLeft:10,marginBottom:15}}>*/}
                                                    {/*                { tender?.decisionIntelligence?.decision_intelligence?.risk_mitigation?.delivery_risks?.map((item) => (<li>{item}</li>))}*/}
                                                    {/*            </ul>*/}
                                                    {/*            <li><b>{Tender.financialRisks}:</b></li>*/}
                                                    {/*            <ul style={{paddingLeft:10,marginBottom:15}}>*/}
                                                    {/*                { tender?.decisionIntelligence?.decision_intelligence?.risk_mitigation?.financial_risks?.map((item) => (<li>{item}</li>))}*/}
                                                    {/*            </ul>*/}
                                                    {/*        </ul>*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                    {/*<Divider />*/}
                                                    <Typography.Title level={3}>{Tender.complianceCertification}</Typography.Title>
                                                    {
                                                        tender?.decisionIntelligence?.decision_intelligence?.compliance_certification && (
                                                            <ul>
                                                                <li><b>{Tender.certifications}:</b></li>
                                                                <ul style={{paddingLeft:10,marginBottom:15}}>
                                                                    { tender?.decisionIntelligence?.decision_intelligence?.compliance_certification?.certifications_needed?.map((item) => (<li>{item}</li>))}
                                                                </ul>
                                                                <li><b>{Tender.documentation}:</b></li>
                                                                <ul style={{paddingLeft:10,marginBottom:15}}>
                                                                    { tender?.decisionIntelligence?.decision_intelligence?.compliance_certification?.documentation_checklist?.map((item) => (<li>{item}</li>))}
                                                                </ul>
                                                                <li><b>{Tender.regulatory}:</b></li>
                                                                <ul style={{paddingLeft:10,marginBottom:15}}>
                                                                    { tender?.decisionIntelligence?.decision_intelligence?.compliance_certification?.regulatory_requirements?.map((item) => (<li>{item}</li>))}
                                                                </ul>
                                                            </ul>
                                                        )
                                                    }
                                                    {/*<Divider />*/}
                                                    {/*<Typography.Title level={3}>{Tender.competitivePositioning}</Typography.Title>*/}
                                                    {/*{*/}
                                                    {/*    tender?.decisionIntelligence?.decision_intelligence?.competitive_positioning && (*/}
                                                    {/*        <ul>*/}
                                                    {/*            <li><b>{Tender.keyDifferentiators}:</b></li>*/}
                                                    {/*            <ul style={{paddingLeft:10,marginBottom:15}}>*/}
                                                    {/*                { tender?.decisionIntelligence?.decision_intelligence?.competitive_positioning?.key_differentiators?.map((item) => (<li>{item}</li>))}*/}
                                                    {/*            </ul>*/}
                                                    {/*            <li><b>{Tender.smallBusinessAdvantage}:</b> <span>{tender?.decisionIntelligence?.decision_intelligence?.competitive_positioning?.emphasized_strengths}</span></li>*/}
                                                    {/*        </ul>*/}
                                                    {/*    )*/}
                                                    {/*}*/}
                                                    {/*<Divider />*/}
                                                    {/*<Typography.Title level={3}>{Tender.actionPlanForYourBusiness}</Typography.Title>*/}
                                                    {/*<b>{Tender.alignStrengthsWithRequirements}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.align_strengths}</span></li>*/}
                                                    {/*</ul>*/}
                                                    {/*<b>{Tender.optimizeProposalDocumentation}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.optimize_proposal}</span></li>*/}
                                                    {/*</ul>*/}
                                                    {/*<b>{Tender.confirmSAMRegistration}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.confirm_sam_registration}</span></li>*/}
                                                    {/*</ul>*/}
                                                    {/*<b>{Tender.refinePricingStrategy}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.refine_pricing_strategy}</span></li>*/}
                                                    {/*</ul>*/}
                                                    {/*<b>{Tender.logisticsPlanning}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.logistics_planning}</span></li>*/}
                                                    {/*</ul>*/}
                                                    {/*<b>{Tender.proactiveCommunication}:</b>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><span>{tender?.decisionIntelligence?.decision_intelligence?.action_plan?.proactive_measures}</span></li>*/}
                                                    {/*</ul>*/}
                                                </Space>
                                            )
                                        }
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </Col>
                        {/*<Col xs={24} sm={24} lg={24} xl={4}>*/}
                        {/*    <div className={'ask'}>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                    </Row>
                </Space>
            </Spin>
        </div>
    );
}