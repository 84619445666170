import React, { useContext } from 'react'
import { Link, useLocation } from "react-router-dom"
import { isMobileOnly, isDesktop } from "react-device-detect"
import { Menu, Collapse, Tag, Space } from 'antd'
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { FaSearch, FaRegCommentAlt, FaQrcode, FaRegBell, FaRegUser, FaBookOpen, FaRegBookmark } from "react-icons/fa";
import language from "../../resources/languages/en_US";

// Components
import UserBox from './user-box'

// Contexts
import GlobalContext from '../../contexts/global'

const { Layouts: { Dashboard }, Share } = language

export default function Index({ collapsed = false, setCollapsed }: { collapsed?: boolean, setCollapsed?: (collapsed: boolean) => void }) {

    const { sources, tendersCount } = useContext(GlobalContext)
    const location = useLocation()
    const opportunities = sources.map(({ id, name, color, key }) => (
        <Menu.Item key={id} icon={<div className={'disabled'} style={{width:10,height:10,minWidth:10,borderRadius:3,backgroundColor:color}}></div>} className={(collapsed && isDesktop) ? 'menu-item-collapsed tender' : 'tender'}>
            <Link to="#" className={'disabled'}>{name}</Link>
        </Menu.Item>
    ))

    return (
        <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['/']}
            defaultOpenKeys={[`/${location.pathname.split('/')[1]}`]}
            selectedKeys={[location.pathname]}
            onSelect={() => setCollapsed && setCollapsed(!collapsed)}
        >
            {
                [
                    {
                        key: '/',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><MdOutlineSpaceDashboard /></div>,
                        label: <Link to="/">{Dashboard.dashboard}</Link>,
                        className: 'dashboard',
                    },
                    {
                        key: '/opportunities',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaSearch /></div>,
                        label: <Link to="/opportunities"><Space size={20}><span>{Dashboard.opportunities}</span>{tendersCount > 0 && <Tag color={'#0084FF'}>{tendersCount} {Share.new}</Tag>}</Space></Link>,
                        className: 'opportunities',
                    },
                    {
                        key: '/bid-submission',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegCommentAlt size={20} /></div>,
                        label: <Link to="#" className={'disabled'}>{Dashboard.bidSubmission}</Link>,
                        className: 'bid-submission disabled',
                    },
                    {
                        key: '/new-search-filters',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegBell size={20} /></div>,
                        label: <Link to="#" className={'disabled'}>{Dashboard.newSearchFilters}</Link>,
                        className: 'new-search-filters disabled',
                    },
                    {
                        key: '/saved-opportunities',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegBookmark /></div>,
                        label: <Link to="/saved-opportunities">{Dashboard.savedOpportunities}</Link>,
                        className: 'saved-opportunities',
                    },
                    {
                        key: '/help-center',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaBookOpen /></div>,
                        label: <Link to="#" className={'disabled'}>{Dashboard.helpCenter}</Link>,
                        className: 'help-center disabled',
                    },
                    {
                        key: '/decision-intelligence',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaQrcode /></div>,
                        label: <Link to="#" className={'disabled'}>{Dashboard.decisionIntelligence}</Link>,
                        className: 'decision-intelligence disabled',
                    },
                    {
                        key: '/profile',
                        icon: <div className={'ant-menu-item-icon-wrapper'}><FaRegUser /></div>,
                        label: <Link to="/profile/my-account">{Dashboard.profile}</Link>,
                        className: 'profile',
                    }
                ].map(({key, icon, label, className}) => (
                    <Menu.Item key={key} icon={icon} className={(collapsed && isDesktop) ? `menu-item-collapsed ${className}` : `${className}`}>
                        {label}
                    </Menu.Item>
                ))
            }
            {
                (collapsed && isDesktop) ? opportunities : (
                    <Collapse className={'felix-ai-history'} defaultActiveKey={['1']} ghost items={[
                        {
                            key: '1',
                            label: Dashboard.felixAIHistory,
                            children: opportunities,
                        }
                    ]} />
                )
            }
            <UserBox collapsed={collapsed} />
        </Menu>
    )
}
