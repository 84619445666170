import React, { Dispatch } from "react";
import _ from "lodash";
import dayjs, {Dayjs} from 'dayjs'
import { ActionType, API } from "../../resources/constants";

// Interfaces
import { AxiosError } from "../../interfaces/axios";
import { Source, Tender, Filter, PrimaryFilter } from "../../interfaces/pages/opportunities";
import { ClassificationsForm } from "../../interfaces/pages/profile"

const { SET_TENDERS_COUNT, SET_FILTER, SET_TENDERS, SET_TENDERS_PAGINATION, SET_SOURCES } = ActionType
const { USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION, TENDER_TENDER_PAGINATE, TENDER_SOURCE_PAGINATE } = API;

export const getTenders = (axios: {get: any, post: any, delete: any, put: any, patch: any, interceptors: any}, primaryFilter: PrimaryFilter, dispatch: Dispatch<{ type: string, payload: object }>) => {
    axios.get(USER_MANAGEMENT_PROFILE_GET_CLASSIFICATION).then((response: { data: ClassificationsForm }) => {
        const { data: { naicsCodes = [], pscCodes = [], buyersInclude = [], buyersExclude = [], setAsideCodes = [], opportunities = [], noticeTypes = []} } = response
        const postedDate: [Dayjs, Dayjs] = [dayjs().subtract(1, 'day'), dayjs()];
        const filter: Filter = {
            buyersInclude,
            buyersExclude,
            naicsCodes,
            pscCodes,
            setAside: setAsideCodes,
            source: opportunities,
            noticeType: noticeTypes,
            postedDate,
        };
        axios.post(TENDER_TENDER_PAGINATE, {
            agency: !_.isEmpty(buyersInclude) ? buyersInclude : undefined,
            agencyExclude: !_.isEmpty(buyersExclude) ? buyersExclude : undefined,
            naicsCode: !_.isEmpty(naicsCodes) ? naicsCodes : undefined,
            pscCode: !_.isEmpty(pscCodes) ? pscCodes : undefined,
            setAside: !_.isEmpty(setAsideCodes) ? setAsideCodes.map((setAsideCode: string) => (setAsideCode === 'Without Set Aside Code') ? 'null' : setAsideCode) : undefined,
            source: !_.isEmpty(opportunities) ? opportunities : undefined,
            noticeType: !_.isEmpty(noticeTypes) ? noticeTypes : undefined,
            publishDate: [postedDate[0].format('YYYY-MM-DD'), postedDate[1].format('YYYY-MM-DD')],
            ...primaryFilter,
        }, { params: { page: 0, size: 6 } }).then((response: { data: {
                items: Tender[],
                total: number,
            }}) => {
            const { items: tenders = [], total: tendersCount = 0 } = response.data
            dispatch({ type: SET_TENDERS_COUNT, payload: { tendersCount }})
            dispatch({ type: SET_TENDERS, payload: { tenders }})
            dispatch({ type: SET_TENDERS_PAGINATION, payload: { tendersPagination: {
                        page: 1,
                        pageSize: 6,
                        total: tendersCount,
                    }}})
            dispatch({ type: SET_FILTER, payload: { filter }})
        }).catch((error: AxiosError) => {})
    }).catch(async (error: AxiosError) => {
        const postedDate: [Dayjs, Dayjs] = [dayjs().subtract(1, 'day'), dayjs()];
        const filter: Filter = {
            postedDate,
        };
        axios.post(TENDER_TENDER_PAGINATE, {
            publishDate: [postedDate[0].format('YYYY-MM-DD'), postedDate[1].format('YYYY-MM-DD')],
            ...primaryFilter,
        }, { params: { page: 0, size: 6 } }).then((response: { data: {
                items: Tender[],
                total: number,
            }}) => {
            const { items: tenders = [], total: tendersCount = 0 } = response.data;
            dispatch({ type: SET_TENDERS_COUNT, payload: { tendersCount }})
            dispatch({ type: SET_TENDERS_PAGINATION, payload: { tendersPagination: {
                        page: 1,
                        pageSize: 6,
                        total: tendersCount,
                    }}})
            dispatch({ type: SET_TENDERS, payload: { tenders }})
            dispatch({ type: SET_FILTER, payload: { filter }})
        }).catch((error: AxiosError) => {})
    });
}

export const getSources = (axios: {get: any, post: any, delete: any, put: any, patch: any, interceptors: any}, dispatch: Dispatch<{ type: string, payload: object }>) => {
    axios.post(TENDER_SOURCE_PAGINATE, {}, { params: { page: 0, size: 100 } }).then((response: { data: {
            items: Source[],
            total: number,
        }}) => {
        const { data: { items: sources, total} } = response
        dispatch({ type: SET_SOURCES, payload: { sources }})
    }).catch( (error: {}) => error );
}