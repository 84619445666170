import React, { createContext, Dispatch  } from 'react';

// Interfaces
import { Source, Tender, Filter, PrimaryFilter } from "../interfaces/pages/opportunities";

export default createContext<{ user: { avatar: string|null, firstName?: string, lastName?: string, email: string|null, otp: boolean, roles: string[] }|null, accessToken: null|string, sources: Source[], tendersCount: number, tenders: Tender[], tendersPagination: { page: number, pageSize: number, total: number }, filter: Filter, primaryFilter: PrimaryFilter, dispatch: Dispatch<{ type: string, payload: object }> }>({
    user: { avatar: null, email: null, otp: false, roles: [] },
    accessToken: null,
    sources: [],
    tendersCount: 0,
    tenders: [],
    tendersPagination: {
        page: 1,
        pageSize: 6,
        total: 0,
    },
    filter: {},
    primaryFilter: {
        expired: true,
        eligible: true,
    },
    dispatch: () => {}
})
