export enum Message {
    SIGN_IN_PROFILE = "SIGN_IN_PROFILE",
    SIGN_OUT_PROFILE = "SIGN_OUT_PROFILE",
    CHANGE_SUCCESS = "CHANGE_SUCCESS",
    UPDATE_PROFILE = "UPDATE_PROFILE",
    UPLOAD_SUCCESS = "UPLOAD_SUCCESS",
    UPLOAD_ERROR = "UPLOAD_ERROR",
    DELETE_MEMBER = "DELETE_MEMBER",
    ADD_MEMBER = "ADD_MEMBER",
    INVITE_MEMBER = "INVITE_MEMBER",
    DELETE_ACCOUNT = "DELETE_ACCOUNT",
    OTP_SUCCESS = "OTP_SUCCESS",
    COPY_TO_CLIPBOARD = "COPY_TO_CLIPBOARD",
}

export enum Plan {
    FELIX_PRO = 'FELIX_PRO',
    FELIX_PRIME = 'FELIX_PRIME',
}

export enum Member {
    MEMBER = 'MEMBER',
    INVITE = 'INVITE',
}

export enum Job {
    Manufacturer = 'MANUFACTURER',
    Trader = 'TRADER',
}

export enum CompanySize {
    Zero_Ten = 'ZERO_TEN',
    Ten_Fifty = 'TEN_FIFTY',
    Fifty_Plus = 'FIFTY_PLUS',
}

export enum TenderType {
    Goods = 'GOODS',
    Service = 'SERVICE',
    Both = 'BOTH',
}

export enum Budget {
    Minus_Five_Hundred = 'MINUS_FIVE_HUNDRED',
    Five_Hundred_One_Million = 'FIVE_HUNDRED_ONE_MILLION',
    One_Million_Plus = 'ONE_MILLION_PLUS',
}

export enum Grant {
    Minus_Five_Hundred = 'MINUS_FIVE_HUNDRED',
    Five_Hundred_One_Million = 'FIVE_HUNDRED_ONE_MILLION',
    One_Million_Plus = 'ONE_MILLION_PLUS',
}

export enum Type {
    US_Tenders = 'US_TENDER',
    US_Grants = 'US_GRANT',
    Europe_Tenders = 'EUROPE_TENDER',
    Europe_Grants = 'EUROPE_GRANT',
    Canada_Tenders = 'CANADA_TENDER',
    Canada_Grants = 'CANADA_GRANT',
}