import React from 'react'
import { useNavigate } from "react-router-dom"
import { Result, Button } from 'antd'
import { Helmet } from "react-helmet";
import language from "../../resources/languages/en_US"

const { App: { title }, Pages: { Payment: PaymentLanguage  }, Share, Validation, Message: MessageLanguage } = language

export default function PaymentError() {

    const navigate = useNavigate()

    return (
        <div className={'payment-error'}>
            <Helmet>
                <title>{title} - {PaymentLanguage.errorTitle}</title>
            </Helmet>
            <Result
                // icon={<img alt={PaymentLanguage.errorTitle} width={300} src={require('../../resources/media/payment/payment-error.png')} />}
                status="error"
                title={PaymentLanguage.errorTitle}
                subTitle={PaymentLanguage.errorDescription}
                extra={<Button onClick={() => navigate('/payment')}>{PaymentLanguage.errorButton}</Button>}
            />
        </div>
    )
}
