// Action Type Constants

export default {
    SET_PENDING_REQUESTS: "SET_PENDING_REQUESTS",
    SET_USER: "SET_USER",
    SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
    SET_SOURCES: "SET_SOURCES",
    SET_TENDERS_COUNT: "SET_TENDERS_COUNT",
    SET_TENDERS: "SET_TENDERS",
    SET_TENDERS_PAGINATION: "SET_TENDERS_PAGINATION",
    SET_FILTER: "SET_FILTER",
    SET_PRIMARY_FILTER: "SET_PRIMARY_FILTER",
}
