import React from 'react'
import { Layout } from 'antd'

import language from "../../resources/languages/en_US";

const { Footer } = Layout
const { Layouts: { Dashboard } } = language

export default function Index() {

    return (
        <Footer>{Dashboard.copyright} v{process.env.REACT_APP_VERSION}</Footer>
    )
}
