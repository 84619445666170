import  { ActionType } from "./../resources/constants"

const { SET_USER, SET_ACCESS_TOKEN, SET_SOURCES, SET_TENDERS_COUNT, SET_TENDERS, SET_TENDERS_PAGINATION, SET_FILTER, SET_PRIMARY_FILTER } = ActionType

export default function GlobalReducer(state: any, action: { type: string, payload?: any }) {
    switch (action.type) {
        case SET_SOURCES:
            return setSources(state,action);
        case SET_USER:
            return setUser(state,action);
        case SET_TENDERS_COUNT:
            return setTendersCount(state,action);
        case SET_TENDERS:
            return setTenders(state,action);
        case SET_TENDERS_PAGINATION:
            return setTendersPagination(state,action);
        case SET_FILTER:
            return setFilter(state,action);
        case SET_PRIMARY_FILTER:
            return setPrimaryFilter(state,action);
        case SET_ACCESS_TOKEN:
            return setAccessToken(state,action);
        default:
            return state;
    }
}

const setSources = (state: any, action: { type: string, payload?: any } ) => {
    const { sources } = action.payload;

    return {
        ...state,
        sources
    }
}

const setUser = (state: any, action: { type: string, payload?: any } ) => {
    const { user } = action.payload;

    return {
        ...state,
        user
    }
}

const setTenders = (state: any, action: { type: string, payload?: any } ) => {
    const { tenders } = action.payload;

    return {
        ...state,
        tenders
    }
}

const setTendersPagination = (state: any, action: { type: string, payload?: any } ) => {
    const { tendersPagination } = action.payload;

    return {
        ...state,
        tendersPagination
    }
}

const setFilter = (state: any, action: { type: string, payload?: any } ) => {
    const { filter } = action.payload;

    return {
        ...state,
        filter
    }
}

const setPrimaryFilter = (state: any, action: { type: string, payload?: any } ) => {
    const { primaryFilter } = action.payload;

    return {
        ...state,
        primaryFilter
    }
}

const setTendersCount = (state: any, action: { type: string, payload?: any } ) => {
    const { tendersCount } = action.payload;

    return {
        ...state,
        tendersCount
    }
}

const setAccessToken = (state: any, action: { type: string, payload?: any } ) => {
    const { accessToken } = action.payload;

    return {
        ...state,
        accessToken
    }
}
