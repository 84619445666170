import React, { useContext, useState } from 'react'
import { Typography, Button, Badge, Tag, Space, Collapse, Row, Col, Flex, App, Modal, InputNumber, Radio, Divider } from 'antd'
import { FaCheckCircle, FaPlus, FaMinus } from "react-icons/fa";
import { Helmet } from "react-helmet"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { API } from "../../resources/constants"
import language from "../../resources/languages/en_US"

// Interfaces
import { AxiosError } from "../../interfaces/axios"

// Contexts
import AxiosContext from "../../contexts/axios"

// Components
import StripeCheckoutForm from './stripe-checkout-form'

const { App: { title }, Pages: { Payment: PaymentLanguage  }, Share, Validation, Message: MessageLanguage } = language
const { USER_MANAGEMENT_PROFILE_STRIPE } = API;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

export default function Index() {

    const [openModal, setOpenModal] = useState(false)
    const [amount, setAmount] = useState<number>(190)
    const [clientSecret, setClientSecret] = useState<string|null>(null);
    const [invoice, setInvoice] = useState<string|null>(null);
    const { axios } = useContext(AxiosContext)
    const { message, modal } = App.useApp()

    return (
        <div className={'payment'}>
            <Helmet>
                <title>{title} - {PaymentLanguage.title}</title>
            </Helmet>
            <Modal
                destroyOnClose={true}
                title={PaymentLanguage.buy}
                open={openModal}
                cancelButtonProps={{
                    style: {display:'none'},
                }}
                okButtonProps={{
                    style: {display:'none'},
                }}
                onCancel={() => setOpenModal(false)}
            >
                {
                    clientSecret && invoice && amount && (
                        <Elements stripe={stripePromise} options={{
                            // passing the client secret obtained from the server
                            clientSecret,
                        }}>
                            <StripeCheckoutForm invoice={invoice} amount={amount} />
                        </Elements>
                    )
                }
            </Modal>
            <div className={'content-wrapper'}>
                <div className={'content'}>
                    <Space direction="vertical" size={60} style={{width:'100%'}}>
                        <Space direction="vertical" size={20} style={{width:'100%'}}>
                            <div>
                                <Typography.Title level={1}>{PaymentLanguage.title}</Typography.Title>
                                <Typography.Title level={2}>{PaymentLanguage.description}</Typography.Title>
                            </div>
                            <div>
                                <Typography.Title level={3}>{PaymentLanguage.choosePlan}</Typography.Title>
                                <Radio.Group defaultValue="monthly" style={{width:'100%',textAlign:'center'}}>
                                    <Radio value="monthly">{PaymentLanguage.monthly}</Radio>
                                    <Radio value="yearly" disabled={true}>{PaymentLanguage.yearly}</Radio>
                                </Radio.Group>
                            </div>
                        </Space>
                        <Row gutter={50}>
                            <Col xs={24} sm={24} lg={24} xl={8}>
                                <div className={'plan basic'}>
                                    <Space direction="vertical" style={{width:'100%'}}>
                                       <div>
                                           <Typography.Title level={3}>{PaymentLanguage.basic}</Typography.Title>
                                           <Typography.Title level={4}><b>$190</b><span> / mo</span></Typography.Title>
                                       </div>
                                        <Divider />
                                        <Space direction="vertical" size={10} style={{width:'100%'}}>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.basic1}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.basic2}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.basic3}</span></Space></Typography.Text>
                                        </Space>
                                        <Space.Compact style={{width:'100%',marginTop:30}}>
                                            <InputNumber
                                                prefix="$"
                                                style={{width:'100%'}}
                                                value={amount}
                                                min={100}
                                                max={190}
                                                formatter={(value: any) => Math.abs(Math.floor(value * 100) / 100).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={(amount) => amount && setAmount(amount) } />
                                            <Button type={'primary'} block onClick={() => axios.get(USER_MANAGEMENT_PROFILE_STRIPE + (amount * 100)).then((response: { data: { clientSecret: string, invoice: string } }) => {
                                                const { data: { clientSecret, invoice } } = response
                                                setClientSecret(clientSecret);
                                                setInvoice(invoice);
                                                setOpenModal(true)
                                            }, (error: AxiosError) => {
                                                const { response: { data } } = error;
                                                setClientSecret(null);
                                                setInvoice(null);
                                                // @ts-ignore
                                                message.error(MessageLanguage[data.message]).then(response => response)
                                            }) }>{PaymentLanguage.payStripe}</Button>
                                        </Space.Compact>
                                    </Space>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} lg={24} xl={8}>
                                <div className={'plan premium'}>
                                    <Space direction="vertical" style={{width:'100%'}}>
                                            <div>
                                                <Flex justify={'space-between'} align={'center'}>
                                                    <Typography.Title level={3}>{PaymentLanguage.premium}</Typography.Title>
                                                    <Tag color='#3FDD78' style={{color:'black'}}>{PaymentLanguage.popular}</Tag>
                                                </Flex>
                                                <Typography.Title level={4}><b>$490</b><span> / mo</span></Typography.Title>
                                            </div>
                                        <Divider />
                                        <Space direction="vertical" size={10} style={{width:'100%'}}>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.premium1}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.premium2}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.premium3}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.premium4}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.premium5}</span></Space></Typography.Text>
                                        </Space>
                                        <Button type={'primary'} block onClick={() => window.location.href = "mailto:info@felixfusion.ai"} style={{marginTop:30}}>{PaymentLanguage.contactUs}</Button>
                                    </Space>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} lg={24} xl={8}>
                                <div className={'plan prime'}>
                                    <Space direction="vertical" style={{width:'100%'}}>
                                        <div>
                                            <div>
                                                <Typography.Title level={3}>{PaymentLanguage.prime}</Typography.Title>
                                                <Typography.Title level={5}>{PaymentLanguage.whiteGloveService}</Typography.Title>
                                            </div>
                                            <Typography.Title level={4}><b>{PaymentLanguage.contactUs}</b><span> / 1 {PaymentLanguage.credit}</span></Typography.Title>
                                            <Button type={'primary'} disabled={true} block style={{marginTop:10}}>{PaymentLanguage.awardedContractAmount}</Button>
                                        </div>
                                        <Divider />
                                        <Space direction="vertical" size={10} style={{width:'100%'}}>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.prime1}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.prime2}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.prime3}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.prime4}</span></Space></Typography.Text>
                                            <Typography.Text><Space size={10}><FaCheckCircle size={20} /><span>{PaymentLanguage.prime5}</span></Space></Typography.Text>
                                        </Space>
                                        <Divider />
                                        <Space direction="vertical" size={20} style={{width:'100%',textAlign:'center'}}>
                                        <Typography.Title level={5}>{PaymentLanguage.creditPackageSize}</Typography.Title>
                                        <Radio.Group defaultValue="x3" style={{width:'100%',fontWeight:"bold"}}>
                                            <Radio value="x3">{PaymentLanguage.x3}</Radio>
                                            <Radio value="x6">{PaymentLanguage.x6}</Radio>
                                            <Radio value="x9">{PaymentLanguage.x9}</Radio>
                                        </Radio.Group>
                                        </Space>
                                        <Button type={'primary'} block onClick={() => window.location.href = "mailto:info@felixfusion.ai"} style={{marginTop:30}}>{PaymentLanguage.contactUs}</Button>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                        <Space direction="vertical" size={50} style={{width:'100%'}} className={'collapse'}>
                            <Typography.Title level={1}>{PaymentLanguage.frequently}</Typography.Title>
                            <Collapse
                                bordered={false}
                                expandIcon={({ isActive }) => isActive ? <FaMinus size={15} /> : <FaPlus size={15} />}
                                defaultActiveKey={['1']}
                            >
                                <Collapse.Panel header={PaymentLanguage.question1} key="1">
                                    <Typography.Paragraph>{PaymentLanguage.answer1}</Typography.Paragraph>
                                </Collapse.Panel>
                                <Collapse.Panel disabled={true} header={PaymentLanguage.question2} key="2">
                                    <Typography.Paragraph>{PaymentLanguage.answer2}</Typography.Paragraph>
                                </Collapse.Panel>
                                <Collapse.Panel disabled={true} header={PaymentLanguage.question3} key="3">
                                    <Typography.Paragraph>{PaymentLanguage.answer3}</Typography.Paragraph>
                                </Collapse.Panel>
                                <Collapse.Panel disabled={true} header={PaymentLanguage.question4} key="4">
                                    <Typography.Paragraph>{PaymentLanguage.answer4}</Typography.Paragraph>
                                </Collapse.Panel>
                                <Collapse.Panel disabled={true} header={PaymentLanguage.question5} key="5">
                                    <Typography.Paragraph>{PaymentLanguage.answer5}</Typography.Paragraph>
                                </Collapse.Panel>
                            </Collapse>
                        </Space>
                    </Space>
                </div>
            </div>
        </div>
    )
}
